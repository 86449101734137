<template>
  <div style="height: 11.94rem">
    <el-upload  :on-change="fileChange" :show-file-list="false">
      <span style="cursor:default">合同模板：</span>
      <button @click="this.fileType = 'proposalFile'">点击上传</button>
    </el-upload>
    <el-button @click="download()">下载模板</el-button>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "ContractUpload",
  methods:{
    fileChange (files, fileList) {
      formData.append('file',fileList[0].raw)
      this.submit();
    },
    submit(){
      //将数据转化为formData格式
      this.$http({
        method: 'post',
        url: '/admin/updateContractModel',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '上传成功',
            message: '',
            type: 'success'
          });
        }else {
          this.$notify({
            title: '上传失败',
            message: '',
            type: 'warning'
          });
        }
      })
    },
    download(){
      this.$http({
        method: 'post',
        url: '/downloadContractModel',
        data: ''
      }).then(res => {
        window.location.href = res.data.url;
      })
    }
  }
}
</script>

<style scoped>

</style>